

<template id="product">
  <section class="hero">
    <div class="hero__left">
      <div class="hero__content">
        <h6 class="hero__title py-10">{{ title }}</h6>
        <p class="hero__text">On had need odd ports, the candidates, few hills posterity most in with everything brown most their partially installer.</p>
        <button class="btn">Buy now</button>
      </div>
    </div>
    <div class="hero__right">
      <div class="hero__overlay"></div>
      <slot  name="image" :image="image" >

      </slot>
    </div>
  </section>
</template>

<script>
export default {

  name: "product",

  props: ['title', 'image'],


/*  props: {

    image: Object

  },*/


}
</script>

<style scoped>
@font-face {
  src: url('https://assets.codepen.io/2479807/GrandSlang-Roman.woff');
  font-family: 'Grand Slang';
}

@font-face {
  src: url('https://assets.codepen.io/2479807/gilroy-semibold.woff2');
  font-family: 'Gilroy Semi-Bold';
}

.hero__title {

  font-size: 8rem;

}

</style>
