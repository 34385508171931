<template id="bakino">
  <product title="Bakino"
  >
    <template v-slot:image>
      <slot>
        <img src="@/assets/bakino.jpg">
      </slot>
    </template>
  </product>
</template>
<script>


import product from "@/components/partials/PageTransitions/PageTransitionsNr1/components/product";

export default {
  name: "bakino",

  data() {


    return {


    }

  },
  components: {
    product
  }
}
</script>

<style scoped>

</style>
